var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ResultWorkOrderForm", {
    attrs: {
      "rwo-id": _vm.$route.params.id,
      "work-order": _vm.workOrder,
      loader: _vm.loading
    },
    on: { back: _vm.handleBack }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }