









import { buildResultWorkOrderFormState } from "@/builder";
import { ResultWorkOrderForm } from "@/components/ResultWorkOrder";
import { ResultWorkOrderMapper } from "@/mapper/ResultWorkOrder.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { resultWorkOrderService } from "@/services/result-work-order.service";
import Vue from "vue";

export default Vue.extend({
  name: "DetailPage",
  components: {
    ResultWorkOrderForm,
  },
  mixins: [MNotificationVue],
  props: {
    id: String,
  },
  data() {
    return {
      workOrder: buildResultWorkOrderFormState(),
      loading: false,
    };
  },
  mounted() {
    this.fetchDetail(this.id);
  },
  methods: {
    async fetchDetail(rwoId: string) {
      try {
        this.loading = true;
        const response = await resultWorkOrderService.getDetail(rwoId);
        this.workOrder =
          ResultWorkOrderMapper.toResultWorkOrderFormState(response);
      } catch {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    handleBack() {
      this.$router.push({
        name: "logistic.work-order.result",
      });
    },
  },
});
